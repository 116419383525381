import store from '@/store'
import deb from 'lodash/debounce'
import _ from 'lodash'

const isFromSchoolPlatform = () => {
  return store.state.mainAppName === 'schoolPlatform'
}

const isFromBackEnd = () => {
  return store.state.mainAppName === 'shenyue'
}

const debounce = (func, wait = 300, opt?) => {
  const defaultOpt = {
    leading: false,
    trailing: true
  }
  return deb(func, wait, opt ?? defaultOpt)
}

const getVarType = variable => {
  // Object.prototype.toString.call() 返回值的形式 - "[Object xxx]"
  // 其中：xxx 即为具体的变量类型
  return Object.prototype.toString
    .call(variable)
    .replace(/\[|\]|"/g, '')
    .replace('object ', '')
    .toLowerCase()
}

/**
 * 连接多个数组，并根据 key 值去除重复
 * @returns 去重后的新数组
 */
const concatWithoutSame = (...params) => {
  // 第一个参数是数组，将所有元素按基本类型处理
  if (getVarType(params[0]) === 'array') {
    return Array.from(new Set([].concat(...params)))
  }

  const [key, ...arrays] = params
  const exist = {} // 记录已存在的值
  const totalArrays = [].concat(...arrays) // 将所有数组连在一起
  return totalArrays.reduce((pre, curr) => {
    if (!exist[curr[key]]) {
      // @ts-ignore
      exist[curr[key]] = true
      pre.push(curr)
    }
    return pre
  }, [])
}

// 列表数据转换为树形格式
const list2tree = (list: any[], { rootId = 0 }: { rootId: number }) => {
  const getTreeData = (pid: number, data: any[]): any =>
    data
      .filter(item => item.parent_id === pid)
      .map(item => {
        const children = getTreeData(item.id, data)
        return children.length > 0 ? { ...item, children } : { ...item }
      })
  return getTreeData(rootId, list)
}

const createRandomCode = (len = 6) => {
  const charset =
    '_0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const maxLen = charset.length
  let ret = ''
  for (let i = 0; i < len; i++) {
    const randomIndex = Math.floor(Math.random() * maxLen)
    ret += charset[randomIndex]
  }
  return ret
}

/**
 * 将对象转成小写头峰
 * @param obj
 * @param newObj
 * @returns
 */
const toCameCaseObj = (obj: Record<string, any>, newObj: any = {}) => {
  if (typeof obj !== 'object') {
    return {}
  }

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      const newArray: any[] = []
      value.forEach(value => {
        if (typeof value === 'object') {
          newArray.push(toCameCaseObj(value[1]))
        } else {
          newArray.push(value)
        }
      })
      newObj[_.camelCase(key)] = newArray
    } else {
      if (typeof value === 'object' && value !== null && value !== undefined) {
        newObj[_.camelCase(key)] = toCameCaseObj(value)
      } else {
        newObj[_.camelCase(key)] = value
      }
    }
  })
  return newObj
}
export {
  isFromSchoolPlatform,
  isFromBackEnd,
  debounce,
  getVarType,
  concatWithoutSame,
  list2tree,
  createRandomCode,
  toCameCaseObj
}
